<!--
 * @Author: your name
 * @Date: 2021-07-29 15:54:09
 * @LastEditTime: 2021-08-25 15:59:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\news\detail.vue
-->
<template>
  <div class="detail">
    <div class="container">
      <links style="float: right" />
      <div class="main">
        <div class="nums flex-h flex-vc">
          <div class="numsLeft flex-h flex-vc">
            <img src="@/assets/img/eye.png" />
            <span>{{ browseNum }}人查看</span>
          </div>
          <span>发布时间：{{ releaseTime }}</span>
        </div>
        <h1 class="pTitle">{{title}}</h1>
        <div v-html="html"></div>
      </div>
    </div>
  </div>
</template>
<script>
import links from "./components/links.vue";
import { getNewsDetail } from "@/api/index";
export default {
  data() {
    return {
      browseNum: "",
      releaseTime: "",
      html: "",
      title:""
    };
  },
  components: { links },
  methods: {
    getData() {
      getNewsDetail(this.$route.query.id).then((res) => {
        this.browseNum = res.data.browseNum;
        this.releaseTime = this.$formatDate(res.data.releaseTime);
        this.html = res.data.content;
        this.title = res.data.title;
      });
    },
  },
  created() {
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  background: #f6f6f6;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 500px;
  .container {
    .main {
      width: 889px;
      overflow-x: auto;
      background: #ffffff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 22px;
      .nums {
        justify-content: space-between;
        margin-bottom: 10px;
        img {
          width: 20px;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          margin-right: 20px;
        }
      }
      .pTitle {
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0c0c0c;
        margin-top: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
</style>